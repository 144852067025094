@import '../vars/variables';

// images folder path of theme 
$base-theme-image-path : '../images'!default;

//------- FONTS
$fa-font-path							: "../fonts/" !default;
$font-icon 								: "FontAwesome"!default;
$font-icon-2                     : "gva-icon"!default;
$font-second                     : "brandon"!default;
//-------- COLOR --------------
$black 			 					   : #121212 !default; 
$white                           : #fff !default;
$nocolor                         : transparent !default;
//--------- COLORS
$theme-color                      : #34aaa6!default;  //#E17A52
$theme-color-rgba					    : rgba(254, 184, 0, 0.8)!default;  //#E17A52
$theme-color-second               : #313131 !default;
$border-color-dark                : rgba(255, 255, 255, 0.1)!default;
$border-color                     : rgba(0, 0, 0, 0.1)!default; 